// check_webp_feature
// https://developers.google.com/speed/webp/faq
function check_webp_feature(callback: (result: boolean) => void) {
  var img = new Image();
  img.onload = function () {
    var result = (img.width > 0) && (img.height > 0);
    callback(result);
  };
  img.onerror = function () {
    callback(false);
  };
  img.src = "data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==";
};
document.addEventListener('DOMContentLoaded', function () {
  check_webp_feature(result => document.body.classList.add((result ? '' : 'no-')+'webp'));
});
